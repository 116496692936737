.header {
    background-color: rgba(250, 250, 250, 0.98);
    display: flex;
    justify-content: space-between;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    position: absolute;
    width: 100%;
    height: 13vw;
    align-items: center;
    position: fixed;
    transition: 0.5s ease-in-out;
    z-index: 30;
    /* backdrop-filter: blur(3px); */
    border-bottom: solid 1px lightgray;
}

.header_active {
    background-color: rgba(255, 255, 255, 0.98);
    display: flex;
    justify-content: space-between;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    position: absolute;
    width: 100%;
    height: 13vw;
    align-items: center;
    position: fixed;
    transition: 0.5s ease-in-out;
    z-index: 60;
    /* backdrop-filter: blur(3px); */
    border-bottom: solid 1px lightgray;
}

.bookVilla_btn {
    background-color: transparent;
    border: solid 1px #737373;
    padding-top: 0.3%;
    padding-bottom: 0.3%;
    padding-left: 3%;
    padding-right: 3%;
    outline: none;
}

.bookVilla_btn:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

@media only screen and (min-device-width: 601px) and (max-device-width: 900px) {
    .header {
        height: 10vw;
    }

    .header_active {
        height: 10vw;
    }

    .header_logo {
        height: 20vw;
        width: 20vw;
    }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
    .header {
        height: 5vw;
    }

    .header_active {
        height: 5vw;
    }

    .header_logo {
        height: 8vw;
        width: 8vw;
    }

    .bookVilla_btn {
        padding-top: 0.3%;
        padding-bottom: 0.3%;
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media only screen and (min-device-width: 1500px) {
    .header {
        height: 5vw;
    }

    .header_active {
        height: 5vw;
    }

    .header_logo {
        height: 10vw;
        width: 10vw;
    }

    .bookVilla_btn {
        padding-top: 0%;
        padding-bottom: 0%;
        padding-left: 3%;
        padding-right: 3%;
    }
}

.bar1,
.bar2 {
    width: 25px;
    height: 2px;
    background-color: black;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 5vw;
    z-index: 50;
}

.change .bar1 {
    transform: translate(0, 3px) rotate(-45deg);
    z-index: 50;
}

.change .bar2 {
    transform: translate(0, -5px) rotate(45deg);
    z-index: 50;
}

.change {
    background-color: transparent;
    color: black;
    outline: none;
    z-index: 50;
}


.menu_bg {
    background: linear-gradient(to left bottom, rgb(255, 255, 255, 1), rgba(255, 255, 255, 0.8));
    transition: 0.8s ease-in-out;
    overflow-y: auto;
}

@media only screen and (min-device-width: 900px) {
    .menu_bg {
        background: linear-gradient(to left, rgb(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
    }
}

.underscore {
    visibility: hidden;
}


/* Footer css starts from here */

.footer_bgImg_m {
    background-image: url('https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/99c53d37-7b24-4b43-4f78-ff2963728a00/public');
    /* m_footer_bg.webp */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.footer_bgImg {
    background-image: url('https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2677377f-2cb7-4790-4c41-6c3392191100/public');
    /* footer_bg.webp */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.floatingAction_btn {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    background-color: #6D6148;
    color: white;
    font-size: 2em;
    border-radius: 5em;
    height: 1.4em;
    width: 1.4em;
}

.rotate_floatingAction_btn {
    font-size: 2vw;
    transform: rotate(135deg);
    transition: transform 0.3s ease;
    background-color: #6D6148;
    color: white;
    font-size: 2em;
    border-radius: 5em;
    height: 1.4em;
    width: 1.4em;
}

.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* background-color: #007bff; */
    color: #fff;
    height: 1.8em;
    width: 1.8em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 10;
    /* Ensure FAB is above the icons */
}

.fab.open i {
    transform: rotate(45deg);
}

.fab-menu {
    position: absolute;
    right: 0px;
    bottom: 100px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 0;
    /* Ensure icons are behind the FAB */
}

.fab.open .fab-menu {
    visibility: visible;
    opacity: 1;
}

.fab-menu a {
    display: block;
    margin-bottom: 0.3em;
    text-align: center;
    color: #fff;
    text-decoration: none;
    /* font-size: 20px; */
    transition: all 0.3s ease;
    transform: translateY(50px);
    /* Initial position off-screen */
}

/* Footer css ends here */