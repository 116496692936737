html {
  scroll-behavior: smooth;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: hsl(0, 0%, 94%);
  border-radius: 40px;
}

.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    hsl(0, 0%, 94%) 0%,
    hsl(0, 0%, 98%) 50%,
    hsl(0, 0%, 94%) 100%
  );
  animation: skeleton-loading 1.5s linear infinite;
}

.skeleton2 {
  position: relative;
  overflow: hidden;
  background-color: hsl(0, 0%, 94%);
  border-radius: auto;
}

.skeleton2::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    hsl(0, 0%, 94%) 0%,
    hsl(0, 0%, 98%) 50%,
    hsl(0, 0%, 94%) 100%
  );
  animation: skeleton-loading 1.5s linear infinite;
}

@keyframes skeleton-loading {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

@font-face {
  font-family: SohamVillas;
  src: url("/public/assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Title;
  src: url("/public/assets/fonts/Aloha_Magazine/AlohaMagazine-Thin.ttf");
  font-display: swap;
}

@font-face {
  font-family: Heading;
  src: url("/public/assets/fonts/Oswald/static/Oswald-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: HeadingLight;
  src: url("/public/assets/fonts/Oswald/static/Oswald-ExtraLight.ttf");
  font-display: swap;
}

@font-face {
  font-family: PageHeading;
  src: url("/public/assets/fonts/Oswald/static/Oswald-ExtraLight.ttf");
  font-display: swap;
}

html {
  font-family: SohamVillas;
}

.title {
  font-family: Title;
}

.heading {
  font-family: Heading;
}

.heading_light {
  font-family: HeadingLight;
}

.page_heading {
  font-family: PageHeading;
}

.no-scroll {
  overflow: hidden;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 0.5rem;
}

.home_bgImg_m {
  background-image: url("https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/90850c2a-9cac-4a43-52b5-6d6ed7954400/public");
  /* m_home3.webp */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home_bgImg {
  background-image: url("https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b90abc9b-4fe0-43a5-f637-4befaa014e00/public");
  /* home5.webp */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.experience_seeFull_btn {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  border: solid 1px gray;
}

.testimonial_bg {
  background-color: #fafafa;
  border-radius: 3rem 3rem 0px 0px;
}

.select_style {
  appearance: none;
}

.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-slide {
  display: flex;
  transition: transform 1.3s ease-in-out;
}

.carousel-image {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Book your villa css stars from here */
.bg_wave {
  background-image: url("../../../public/assets/wave.webp");
  /* wave.webp */
  background-size: contain;
  background-repeat: no-repeat;
}

.popupForm_BookingMobile {
  height: auto;
  width: 70%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1%;
  border: 1px solid #ccc;
  z-index: 60;
  border-radius: 10px;
}

.overlay_BookingMobile {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.close_BookingMobile {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

.popupForm_rooms_guests {
  height: auto;
  width: auto;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1%;
  border: 1px solid #ccc;
  z-index: 60;
  border-radius: 10px;
}

.overlay_rooms_guests {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.close_rooms_guests {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

.popupForm_calendar {
  height: auto;
  max-height: 90vh;
  width: auto;
  display: block;
  position: fixed;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1%;
  border: 1px solid #ccc;
  z-index: 9999 !important;
  border-radius: 10px;
  overflow: auto;
}

@media only screen and (min-device-width: 1500px) {
  .popupForm_calendar {
    max-height: 90vh;
  }
}

.overlay_calendar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998 !important;
}

.close_calendar {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}
/* Book your villa css ends here */

/* Our story css starts from here */

.bg_soham {
  background-image: url("../../../public/assets/soham_bg.webp");
  /* soham_bg.webp */
  background-size: contain;
  background-repeat: no-repeat;
}

/* Our story css ends here */

/* Account css starts from here */

.toggle_btn {
  display: inline;
  margin-right: 5%;
  border: none;
  outline: none;
  background-color: #fafafa;
  color: gray;
}

@media only screen and (min-device-width: 900px) {
  .toggle_btn {
    margin-right: 3%;
  }
}

.toggle_btn:hover {
  text-decoration: underline;
  text-decoration-color: black;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
  color: black;
}

.active_toggle_btn {
  text-decoration: underline;
  text-decoration-color: black;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
  color: black;
}

.popup_form_Logout {
  height: auto;
  width: 70%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  background-color: white;
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 2;
}

@media only screen and (min-device-width: 900px) {
  .popup_form_Logout {
    padding: 1%;
    width: 400px;
  }
}

.overlay_Logout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.close_Logout {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

:where(.css-dev-only-do-not-override-17seli4).ant-pagination
  .ant-pagination-item-active
  a {
  color: #877d6f !important;
}

:where(.css-dev-only-do-not-override-17seli4).ant-pagination
  .ant-pagination-item-active {
  /* font-weight: 600; */
  /* background-color: #ffffff; */
  border-color: #877d6f !important;
}

/* Account css ends here */

/* Sign css starts from here */
.container_SignIn {
  height: auto;
  width: 500px;
  /* background-color: white; */
  /* box-shadow: 8px 8px 20px rgb(128, 128, 128); */
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  /* background-color: palegreen; */
}

.btn {
  height: 40px;
  width: 500px;
  margin: 20px auto;
  /* box-shadow: 2px 2px 10px rgb(171, 160, 153); */
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #efefef;
  /* border: solid black; */
}

.login,
.signup {
  /* font-size: 22px; */
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
}

.slider {
  height: 30px;
  width: 245px;
  border-radius: 5px;
  /* background-image: linear-gradient(to right,rgb(173, 166, 155),rgb(211, 200, 194)); */
  background-color: white;
  position: absolute;
  top: 25px;
  left: 5px;
  transition: all 0.5s ease-in-out;
  /* background-color: rebeccapurple; */
}

.moveslider {
  left: 250px;
  /* background-color: red; */
}

.form-section {
  /* height: 500px; */
  width: 1000px;
  padding: 20px 0;
  display: flex;
  position: relative;
  transition: all 0.5s ease-in-out;
  left: 0px;
  /* background-color: aqua; */
}

.form-section-move {
  left: -500px;
}

.login-box,
.signup-box {
  height: 60%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
  /* background-color: rebeccapurple; */
}

.login-box {
  gap: 10px;
}

.signup-box {
  gap: 10px;
}

.ele {
  height: 60px;
  width: 400px;
  outline: none;
  border: none;
  color: rgb(77, 77, 77);
  background-color: rgb(240, 240, 240);
  border-radius: 50px;
  padding-left: 30px;
  font-size: 18px;
}

.clkbtn {
  height: 40px;
  width: 400px;
  border-radius: 50px;
  /* background-image: linear-gradient(to right,rgb(173, 166, 155),rgb(211, 200, 194)); */
  background-color: black;
  color: white;
  /* font-size: 22px; */
  border: none;
  cursor: pointer;
}

/* For Responsiveness of the page */

@media screen and (max-width: 650px) {
  .container_SignIn {
    height: auto;
    width: 300px;
    /* background-color: lightcoral; */
  }

  /* .title {
    font-size: 15px;
  } */

  .btn {
    height: 40px;
    width: 300px;
    margin: 20px auto;
  }

  .login,
  .signup {
    font-size: 19px;
  }

  .slider {
    height: 30px;
    width: 145px;
    left: 5px;
  }

  .moveslider {
    left: 150px;
  }

  .form-section {
    /* height: 500px; */
    width: 600px;
  }

  .form-section-move {
    left: -300px;
  }

  .login-box,
  .signup-box {
    height: 50%;
    width: 300px;
    /* background-color: red; */
  }

  .ele {
    height: 50px;
    width: 250px;
    /* font-size: 15px; */
  }

  .clkbtn {
    /* height: 30px; */
    width: 300px;
    /* font-size: 19px; */
  }
}

@media screen and (max-width: 320px) {
  .container_SignIn {
    height: auto;
    width: 250px;
  }

  /* .heading {
    font-size: 30px;
  }

  .title {
    font-size: 10px;
  } */

  .btn {
    height: 40px;
    width: 220px;
    margin: 20px auto;
  }

  /* .login,
  .signup {
    font-size: 19px;
  } */

  .slider {
    height: 30px;
    width: 100px;
    left: 20px;
  }

  .moveslider {
    left: 130px;
  }

  .form-section {
    /* height: 500px; */
    width: 500px;
  }

  .form-section-move {
    left: -250px;
  }

  .login-box,
  .signup-box {
    height: 50%;
    width: 250px;
    /* background-color: yellow; */
  }

  .ele {
    height: 50px;
    width: 220px;
    /* font-size: 15px; */
  }

  .clkbtn {
    height: 35px;
    width: 220px;
    /* font-size: 19px; */
  }
}

/* Sign in css ends here */

/* Villa Details page css starts from here */
.react-multiple-carousel__arrow {
  /* position: absolute !important; */
  outline: 0 !important;
  transition: all 0.5s !important;
  border-radius: 200px !important;
  z-index: 1 !important;
  border: 0 !important;
  background: rgba(255, 255, 255, 0) !important;
  min-width: 28px !important;
  min-height: 10px !important;
  opacity: 1 !important;
  cursor: pointer !important;
  /* top: 1% !important; */
}

.react-multiple-carousel__arrow::before {
  font-size: 10px !important;
  color: black !important;
}

.popup_form_Photos {
  height: auto;
  width: 95%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1% 0%;
  border: 1px solid #ccc;
  z-index: 100;
  border-radius: 8px;
}

@media only screen and (min-device-width: 701px) {
  .popup_form_Photos {
    width: 70%;
    border-radius: 10px;
  }
}

@media only screen and (min-device-width: 900px) {
  .popup_form_Photos {
    width: 80%;
  }
}

.overlay_Photos {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.close_Photos {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 25px;
  cursor: pointer;
  margin-left: auto;
  color: #877d6f;
}

.slick-prev:before,
.slick-next:before {
  color: #877d6f !important;
}

.popup_form_LoginPopup {
  height: auto;
  width: 80%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 3%;
  border: 1px solid #ccc;
  z-index: 100;
  border-radius: 10px;
}

@media (min-width: 1024px) {
  .popup_form_LoginPopup {
    width: 30%;
    padding: 1%;
  }
}

.overlay_LoginPopup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.close_LoginPopup {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  color: black;
}

.lockedIntoView {
  position: fixed;
  top: 0;
}

.rdrCalendarWrapper {
  width: 30em !important;
  font-size: 12px !important;
}

.rdrMonth {
  width: 30em !important;
  /* height: 20em!important;  */
}

.rdrDay {
  height: 5em !important;
  line-height: 5em !important;
}

/* Villa Details page css ends here */

/* Experience Page css starts from here */

.bg_water {
  background-image: url("https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d9abe49c-e20d-4868-058f-fc99f81d4900/public");
  /* bg_water.webp */
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 117, 132, 0.828);
}

/* .reveal_txt::before { 
  content: "SATISFACTION..."; 
  animation: animate infinite 15s; 
} 

@keyframes animate { 

  0% { 
      content: "SATISFACTION..."; 
  } 
  25%{
    content: "EXCLUSIVITY..."; 
  }
  50% { 
      content: "RELAXATION..."; 
  } 

  75% { 
      content: "INSPIRATION..."; 
  } 
}  */

.words {
  overflow: hidden;
  /* background-color: red; */
  height: 32px;
}

@media only screen and (min-device-width: 1500px) {
  .words {
    height: 52px;
  }
}

.words span {
  animation: spin_words 10s infinite;
}

@keyframes spin_words {
  25% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(-200%);
  }

  75% {
    transform: translateY(-300%);
  }

  100% {
    transform: translateY(-0%);
  }
}

/* Experience Page css ends here */

/* Property management page css starts from here */
.plant_bg {
  background-image: url("https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1867e2b9-279c-42e0-68fd-bd02aad46600/public");
  /* plant.webp */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 50%;
}

@media only screen and (min-device-width: 1100px) {
  .plant_bg {
    background-size: 30%;
  }
}

.small_circle1 {
  animation: bounce_circle1 10s infinite;
}

@keyframes bounce_circle1 {
  50% {
    transform: translate(-35%, -40%);
  }

  100% {
    transform: translate(-0%, 0%);
  }
}

.small_circle2 {
  animation: bounce_circle2 10s infinite;
}

@keyframes bounce_circle2 {
  50% {
    transform: translate(-35%, -20%);
  }

  100% {
    transform: translate(-0%, 0%);
  }
}

.small_circle3 {
  animation: bounce_circle3 10s infinite;
}

@keyframes bounce_circle3 {
  50% {
    transform: translate(-35%, 20%);
  }

  100% {
    transform: translate(-0%, 0%);
  }
}

.small_circle4 {
  animation: bounce_circle4 10s infinite;
}

@keyframes bounce_circle4 {
  50% {
    transform: translate(-35%, 40%);
  }

  100% {
    transform: translate(-0%, 0%);
  }
}

.brown_bg {
  background-image: url("../../../public/assets/brown_bg.webp");
  /* brown_bg.webp */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  /* background-size: 50%;  */
}

/* Property management page css ends here */

/* Concierge services page css starts from here */

.popup_form_Contact {
  height: auto;
  width: 70%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  background-color: white;
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 2;
}

@media only screen and (min-device-width: 900px) {
  .popup_form_Contact {
    padding: 1%;
    width: 25%;
  }
}

.overlay_Contact {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.close_Contact {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

/* Concierge services page css ends here */

/* Booking confirmation page css starts from  here */

.Btn_download_btn {
  width: 33px;
  height: 33px;
  border: none;
  border-radius: 5px;
  background-color: #444444;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11); */
}

.svgIcon_download_btn {
  fill: rgb(255, 255, 255);
}

.icon2_download_btn {
  width: 15px;
  height: 4px;
  border-bottom: 2px solid rgb(255, 255, 255);
  border-left: 2px solid rgb(255, 255, 255);
  border-right: 2px solid rgb(255, 255, 255);
}

.tooltip_download_btn {
  position: absolute;
  top: -45px;
  opacity: 0;
  background-color: rgb(37, 37, 37);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}

.tooltip_download_btn::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(37, 37, 37);
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  bottom: -5%;
  transition-duration: 0.3s;
}

.Btn_download_btn:hover .tooltip_download_btn {
  opacity: 1;
  transition-duration: 0.3s;
}

.Btn_download_btn:hover {
  background-color: #877d6f;
  transition-duration: 0.3s;
}

.Btn_download_btn:hover .icon2_download_btn {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

.Btn_download_btn:hover .svgIcon_download_btn {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Booking confirmation page css ends  here */
